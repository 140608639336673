import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'

function HeroSlider({images, homeslideSettings}) {
  const settings = {...homeslideSettings}
  return (
    <><Slider {...settings}>      
      {images.map(image => (
        <div className='slider-wrap d-flex align-items-center'>
          <div className='slide-text'>
            <h5 className='mb-xl-4 mb-2'>{image.slideLabel}</h5>
            <h1 className='fw-bold'>{image.slideHeading}</h1>
            
            <div className='slide-bottom d-flex align-items-center'>
              <div className='slide-action'>
                <Link className='btn theme-btn text-nowrap fw-semibold outline-theme-btn overflow-hidden text-capitalize position-relative'><span>Get Started</span>
                  <FontAwesomeIcon icon={faAnglesRight} />
                </Link>
              </div>
              <div className='slide-paragraph d-flex align-items-center'>
                <div className='long-arrow pe-4'>
                  <img src={image.longArrowImg} alt='' title='' />
                </div>
                <p className='m-0'>{image.slidePara}</p>
              </div>
            </div>
          </div>
          <div className='slide-img position-relative overflow-hidden'>
            <div className='slide-img-card'>
              <div className='slide-outline-text text-uppercase position-absolute'>
                <h1>ecofocus</h1>
              </div>
              <div className='slide-feature-img'>
                <img src={image.imageUrl} className='w-100' alt='' title='' />
              </div>
            </div>
          </div>
        </div>
      ))}      
    </Slider>
    </>
  )
}

export default HeroSlider