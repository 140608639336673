import React from 'react'
import HomeHero from '../../components/HomeHero'
import HomeAbout from './HomeAbout'
import KnowEnfocus from './KnowEnfocus'
import EnfocusHighLights from './EnfocusHighLights'

function Home() {
  return (
    <>
        <HomeHero/>
        <HomeAbout/>
        <KnowEnfocus/>
        <EnfocusHighLights/>
    </>
  )
}

export default Home