import React from 'react'
import Form from 'react-bootstrap/Form';

function EnquiryForm() {
  return (
    <section>
            <div className='enquiry-form-wrapper'>
                <div className='container custom-container'>
                    <div className='enquiry-heading'>
                        <h2>Enquiry Form</h2>
                    </div>
                    <Form>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control className='custom-input' type="text" placeholder="Your Name" />
                                </Form.Group>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" className='custom-input'  placeholder="Your Email" />
                                </Form.Group>
                            </div>
                            <div className='col-lg-4'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="number" className='custom-input'  placeholder="Your Number" />
                                </Form.Group>
                            </div>
                            <div className='col-lg-12'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control  className='custom-input' as="textarea" rows={5} placeholder='Message' />
                                </Form.Group>
                            </div>
                            <div className='col-lg-12'>                                
                                <input type='submit' value='submit' className='btn contact-btn theme-btn text-nowrap fw-semibold overflow-hidden text-capitalize position-relative'/>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
  )
}

export default EnquiryForm
