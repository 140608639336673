import React from 'react'
import whiteLogo from '../assets/images/enfocus-white.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap-v5';

function Footer() {
  const aboutEnfocus = 'About Enfocus'
  const linkEnfocus = 'quick links'
  const getinTouch = 'Get in touch'
  const footerPara = 'Enfocus Energy, is a renewable energy company based in Chandigarh, India, committed to provide the latest environment friendly and state of the art renewable energy solutions in North India, but with a vision to expand Pan-India. We have involved management of Clarusys Inc. USA, for introducing high standards of renewable energy products and services in India.'
  return (
    <>
      <footer>
        <div className='enfocus-newsletter'>
          <div className='container custom-container'>
            <div className='row align-items-center'>
              <div className='col-md-3 d-none'>
                <div className='newsletter-logo'>
                  <img src={whiteLogo} alt='' title='' />
                </div>
              </div>
              <div className='col-xl-7 col-lg-10 m-auto'>
                <form action='#' method='get'>
                  <div className='position-relative d-flex'>
                    <input type='email' className='form-control' placeholder='Your email address' />
                    <FontAwesomeIcon icon={faEnvelope} className='position-absolute start-0 top-0 opacity-50 h-100 d-flex align-items-center' />
                    <Button type='submit' className='btn theme-btn text-nowrap fw-semibold overflow-hidden text-capitalize position-relative'><FontAwesomeIcon className='m-0 d-none' icon={faPaperPlane} />Subscribe</Button>
                  </div>
                </form>
              </div>
              <div className='col-md-4 d-none'>
                <div className='enfocus-social d-flex justify-content-end'>
                  <Link className='d-flex align-items-center justify-content-center rounded-circle text-decoration-none smooth' to=''><FontAwesomeIcon icon={faFacebookF} /></Link>
                  <Link className='d-flex align-items-center justify-content-center rounded-circle text-decoration-none smooth' to=''><FontAwesomeIcon icon={faInstagram} /></Link>
                  <Link className='d-flex align-items-center justify-content-center rounded-circle text-decoration-none smooth' to=''><FontAwesomeIcon icon={faTwitter} /></Link>
                  <Link className='d-flex align-items-center justify-content-center rounded-circle text-decoration-none smooth' to=''><FontAwesomeIcon icon={faLinkedinIn} /></Link>
                  <Link className='d-flex align-items-center justify-content-center rounded-circle text-decoration-none smooth' to=''><FontAwesomeIcon icon={faYoutube} /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* newsletter */}

        <div className='main-footer'>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-xl-4 col-lg-5 col-md-12 mb-lg-0 mb-md-5 mb-4'>
                <div className='footer-heading text-capitalize'>
                  <h3 className='fw-semibold mb-4'>{aboutEnfocus}</h3>
                  <p className='m-0'>{footerPara}</p>
                </div>
              </div>
              {/* /col-md-4 */}

              <div className='col-xl-4 col-lg-3 col-md-5 ps-lg-5 mb-md-0 mb-4'>
                <div className='footer-heading text-capitalize'>
                  <h3 className='fw-semibold mb-4'>{linkEnfocus}</h3>
                </div>
                <div className='footer-links d-flex'>
                  <ul className='quick-links list-unstyled p-0 m-0'>
                    <li><Link>Home</Link></li>
                    <li><Link to='/about-us'>About Us</Link></li>
                    <li><Link>Photos</Link></li>
                    <li><Link>Privacy Policy</Link></li>
                    <li><Link>Products</Link></li>
                  </ul>
                  <ul className='quick-links list-unstyled p-0 m-0'>
                    <li><Link>Projects</Link></li>
                    <li><Link>Terms Of Use</Link></li>
                    <li><Link>Videos</Link></li>
                  </ul>
                </div>
              </div>

              <div className='col-xl-4 col-lg-4 col-md-7 ps-xl-5'>
                <div className='footer-heading text-capitalize'>
                  <h3 className='fw-semibold mb-4'>{getinTouch}</h3>
                </div>
                <address className='mb-5'>
                  <h5 className='fw-semibold mb-3'>India Office</h5>
                  <p>Plot No.229, 1st Floor, Industrial Area, Phase-1, Panchkula (HR) Ph: 0172-4649954</p>
                </address>
                <address>
                  <h5 className='fw-semibold mb-3'>US Office</h5>
                  <p>2375 E. Camelback Road, Suite 600, Phoenix, AZ 85016, USA</p>
                </address>
              </div>

            </div>
          </div>
        </div>
        {/* main-footer */}

        <div className='footer-copyright text-center p-lg-3 p-3 '>
        <p>&copy; Copyright 2023 <Link to="/">Enfocus Energy</Link> All Rights Reserved</p>
        </div>

      </footer>
    </>
  )
}

export default Footer