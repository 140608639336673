import React from 'react'
import EnquiryForm from '../../components/EnquiryForm'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ContactUs() {
  const aboutHeading = "Contact Us"
  return (
    <>
      <section>
            <div className="inner-banner">
                <div className="container custom-container">
                    <div className="breadcrumb-wrapper">
                        <div className="about-heading text-center">
                            <h2>{aboutHeading}</h2>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* end banner */}

        <section>
          <div className='inner-page-wrapper'>
              <div className='container custom-container'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='contact-info'>
                      <h4>India Office</h4>
                      <div className='address-info d-flex align-item-center'>
                        <span className='icon-wrapper'><FontAwesomeIcon className='m-0 location-icon' icon={faLocationDot} /></span>
                        <p className='ps-3'>Plot No.229, 1st Floor, Industrial Area, Phase-1, Panchkula (HR) Ph: 0172-4649954</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='contact-info mb-lg-0 mb-md-0'>
                      <h4>USA Office</h4>
                      <div className='address-info d-flex align-items-center'>
                        <span className='icon-wrapper'><FontAwesomeIcon className='m-0 location-icon' icon={faLocationDot} /></span>
                        <p className='ps-3 mb-0'>2375 E. Camelback Road, Suite 600, Phoenix, AZ 85016, USA</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>

        <EnquiryForm/>

    </>
  )
}

export default ContactUs