import React from 'react'
import { Route, Routes } from 'react-router'
import Home from '../pages/home/Home'
import Products from '../pages/products/Products'
import Projects from '../pages/projects/Projects'
import Media from '../pages/media/Media'
import ContactUs from '../pages/contact us/ContactUs'
import AboutUs from '../pages/about us/AboutUs'

function AllRoutes() {
  return (
    <>
        <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/products' element={<Products/>}></Route>
        <Route path='/projects' element={<Projects/>}></Route>
        <Route path='/media' element={<Media/>}></Route>
        <Route path='/contact-us' element={<ContactUs/>}></Route>
        <Route path='/about-us' element={<AboutUs/>}></Route>
        </Routes>
    </>
  )
}

export default AllRoutes