import React from 'react'
import HeroSlider from './HeroSlider'
import Slide1 from '../assets/images/eco-friendly.jpg'
import Slide2 from '../assets/images/solar-power.jpg'
import longArrow from '../assets/images/arrow-long.png'

function HomeHero() {

  const images = [
    {imageUrl: Slide1,slideLabel:'Enfocus Energy is an India based company',slideHeading:'Eco-Friendly-Build & Save your Planet', slidePara:'We, Enfocus Energy are proud providers of Consultation, Design, Engineering, fabrication, Integration and installation of, Commercial Carport based PV Solar systems...', longArrowImg: longArrow},

    {imageUrl: Slide2,slideLabel:'Enfocus Energy is an India based company',slideHeading:'Set Great example using Solar Electric Power.', slidePara:'Enfocus is dedicated to offer an affordable yet durable and high-quality energy resources for  both, residential as well as commercial clients.', longArrowImg: longArrow}
    
  ]

  const homeslideSettings = {
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      draggable: false,
      arrows: false,
  };
  return (
    <>
        <div className='enfocus-hero'>
            <div className='container custom-container'>
              <div className='row'>
                <div className='col-12'>
                  <HeroSlider homeslideSettings={homeslideSettings} images={images} />
                </div>
              </div>
            </div>
        </div>
    </>
  )
}

export default HomeHero