import React from 'react'
import headingLeaft from '../../assets/images/leaft.png'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import aboutImage from '../../assets/images/about.png'

function HomeAbout() {
    const sectionHeading = 'What We Do'
    const para = 'We, Enfocus Energy., are proud providers of Consultation, Design, Engineering, fabrication, Integration and installation of, Commercial Carport based PV Solar systems, Commercial, Residential roof top PV Solar Systems and innovative small wind turbines for commercial and high-rise residential buildings. We undertake above services individually or as turnkey projects. Once the client places an order, provides the site and Govt permits etc., we start the projects and hand over an operational system ready to be interconnected to the grid. Should the client desire, we shall also undertake liaison with the Govt and electric Company for permission/interconnection.'
  return (
    <>
        <section>
            <div className='about-enfocus section-padding'>
                <div className='container custom-container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 pe-lg-4'>
                            <div className='home-about-img'>
                                <img className='w-100' src={aboutImage} alt={sectionHeading} title={sectionHeading} />
                            </div>
                        </div>
                        <div className='col-lg-6 ps-xxl-5'>
                            <div className='enfocus-heading'>
                                <span className='text-uppercase fw-bold mb-xl-3 mb-2 d-flex align-items-center'><img src={headingLeaft} alt='' title='' /> About Us</span>
                                <h2 className='fw-bold'>{parse(sectionHeading)}</h2>
                            </div>
                            <div className='section-para'>
                                <p>{parse(para)}</p>
                            </div>
                            <div className='feature-points d-none'>
                                <ul className='list-unstyled m-0 p-0'>
                                    <li><span>High efficiency</span></li>
                                    <li><span>Outstanding low-light performance</span></li>
                                    <li><span>High transparent low-iron, tempered glass</span></li>
                                    <li><span>25 year transferable power output warranty</span></li>
                                </ul>
                            </div>
                            <div className='section-action'>
                                <Link to='/about-us' className='btn theme-btn text-nowrap fw-semibold outline-theme-btn overflow-hidden text-capitalize position-relative'><span>Learn More</span> <FontAwesomeIcon icon={faAnglesRight}/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default HomeAbout