import React from 'react'
import galleryImage1 from '../../assets/images/gallery-1.jpg'
import galleryImage2 from '../../assets/images/gallery-2.jpg'
import galleryImage3 from '../../assets/images/gallery-3.jpg'
import galleryImage4 from '../../assets/images/gallery-4.jpg'
import EnquiryForm from '../../components/EnquiryForm'  

function Media() {
  const aboutHeading = "Media"
  return (
    <>
      <section>
            <div className="coming-soon section-padding text-center opacity-25">
                <h1>Coming Soon...</h1>
            </div>
        </section>
        {/* end banner */}
        {/* end about section */}
    </>
  )
}

export default Media