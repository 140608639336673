import React from 'react'

function HighlightCard({highlightIcon, highlightTitle}) {
  return (
    <>
        <div className='highlight-card-box text-center'>
            <div className='highlight-box-icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                <img src={highlightIcon} alt={highlightTitle} title={highlightTitle} />
            </div>
            <div className='highlight-card-body mt-2 mt-xxl-3'>
              <h6 className='text-uppercase fw-semibold'>{highlightTitle}</h6>
            </div>
        </div>
    </>
  )
}

export default HighlightCard