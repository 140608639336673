import React from 'react'
import parse from 'html-react-parser'
import headingLeaft from '../../assets/images/leaft.png'
import highCardImage from '../../assets/images/quality-highlights.jpg'
import iconOne from '../../assets/images/efficiency.svg'
import iconTwo from '../../assets/images/warranty.svg'
import iconThree from '../../assets/images/certified.svg'
import iconFour from '../../assets/images/24x7.svg'
import iconFive from '../../assets/images/energy-production.svg'
import iconSix from '../../assets/images/availability.svg'
import HighlightCard from './components/HighlightCard'

function EnfocusHighLights() {    
    const sectionHeading= 'Quality Work Panels with Us Workmanship'
    const para = 'We at Enfocus guarantee highest standards of workmanship in all of our installations. Our PV Solar panels and inverters are manufactured by Tier-1 manufacturers, carrying maximum warranties in the industry.'

    const highlightCards = [
        {id:1,highlightIcon: iconOne, highlightTitle: 'High efficiency'},
        {id:2,highlightIcon: iconTwo, highlightTitle: '25 Years Warranty'},
        {id:3,highlightIcon: iconThree, highlightTitle: 'ISO 9001: 2001'},
        {id:4,highlightIcon: iconFour, highlightTitle: '24/7 Performance Monitoring'},
        {id:5,highlightIcon: iconFive, highlightTitle: 'Maximum energy production'},
        {id:6,highlightIcon: iconSix, highlightTitle: '99.8% system up time availability'}
    ]

  return (
    <>
        <section>
            <div className='enfocus-highlights section-padding'>
                <div className='container custom-container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='highlights-card overflow-hidden'>
                                <div className='high-card d-flex align-items-center'>
                                    <div className='high-card-img'>
                                        <img className='w-100' src={highCardImage} alt={sectionHeading} title={sectionHeading} />
                                    </div>
                                    <div className='high-card-body'>
                                        <div className='enfocus-heading'>
                                            <span className='text-uppercase fw-bold mb-xl-3 mb-2 d-flex align-items-center'><img src={headingLeaft} alt='' title='' /> Solar Highlights</span>
                                            <h2 className='fw-bold'>{parse(sectionHeading)}</h2>
                                        </div>
                                        <div className='highlight-para mt-xxl-4 mt-3'>
                                            <p>{parse(para)}</p>
                                        </div>
                                        <div  className='feature-highlights d-flex flex-wrap'>
                                            {highlightCards.map((item) =>(
                                                <HighlightCard
                                                    key={item.id}
                                                    highlightIcon={item.highlightIcon}
                                                    highlightTitle={item.highlightTitle}
                                                    highlightPara={item.highlightPara}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default EnfocusHighLights