import React from 'react'
import parse from 'html-react-parser'
import aboutImage from '../../assets/images/project.jpg'
import EnquiryForm from '../../components/EnquiryForm'

function Products() {
  const aboutHeading = "Products"
  const projectsParagraph = "<p>These are the main energy generation components of a PV Solar system, therefore, we only install latest technology PV Solar panels from Tier 1 manufacturers for their high efficiency and maximum warrantee coverage</p>"
  const projectsecondpara = "SABS™ is a revolutionary building and structural technology which is bound to make construction faster, cost effective, more energy and environmentally proficient and safer. SABS™ buildings can be built virtually in any type of environment in any region of the world."

  return (
    
    <>
      <section>
        <div className="inner-banner">
            <div className="container custom-container">
                <div className="breadcrumb-wrapper">
                    <div className="about-heading text-center">
                        <h2>{aboutHeading}</h2>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
      </section>

      <section>
        <div className='inner-page-wrapper'>
          <div className='container custom-container'>
              <div className='row'>
                <div className='col-lg-7'>
                  <div className='projects-wrapper'>
                    <h3>Photo-Voltaic (PV) Solar Panels </h3>
                    <div className='section-para'>
                        {parse(projectsParagraph)}

                        <div className='product-content mb-4'>
                          <h5>Inverters</h5>
                          <p>These devices convert DC power generated by PV Solar panels, or Wind turbines into AC power 
                          for use in the buildings or off-loading to the Grid. These devices should have high efficiency so 
                          that there is minimal degradation of energy generated by energy generation sources. 
                          Simultaneously, these should have longest warrantee coverage, easy to maintain and 
                          communicate with monitoring devices. We shall be using only those inverters that meet our 
                          higher standards as well as fulfil above requirements.</p>
                        </div>

                        <div className='product-content mb-4'>
                          <h5>Wind Turbines </h5>
                          <p>In situations where we recommend use of small wind turbines as energy generation systems, we 
have shortlisted vertical axis turbines with Maglev technology for high efficiency and minimal 
maintenance over prolonged periods. </p>
                        </div>

                        <div className='product-content mb-4'>
                          <h5>Mounting Systems and Racking</h5>
                          <p> In order to maintain structural integrity of the buildings on which the energy generation systems 
are mounted, the Mounting systems and Racking should be light, yet strong enough to withstand 
weight of the energy generation systems as well as maximum wind loads expected in the regions 
they are installed. We generally use a mix of Galvanised steel and Aluminium to meet the above 
standards. For firmly fixing the mountings/Racking on the roofs, yet avoiding any rroof leakages, 
we use high technology fasteners for minimal roof penetrations.  </p>
                        </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-5'>
                  <div className='home-about-img'>
                    <img className='w-100' src={aboutImage} alt='' title='' />
                  </div>
                </div>
                <div className='col-lg-12'>
                  
                  <div className='product-content mb-4'>
                          <h5>
Monitoring Systems</h5>
                          <p>We always recommend to our clients to use efficient and real-time monitoring systems that we 
provide for keeping a track of their energy production as well as for making sure their energy 
generation systems are operating efficiently. These systems can be wired or based on Wi-fi and 
connected to the clients mobile/desktop devices. We also undertake monitoring of the systems 
installed by us over the internet, should it be desired by our clients.</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>

      <EnquiryForm/>
    </>
  )
}

export default Products