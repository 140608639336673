import React from 'react'
import parse from 'html-react-parser'
import projectImage1 from '../../assets/images/project-1.jpg'
import projectImage2 from '../../assets/images/project-2.jpg'
import projectImage3 from '../../assets/images/project-3.jpg'
import projectImage4 from '../../assets/images/project-4.jpg'
import projectImage5 from '../../assets/images/project-5.jpg'
import EnquiryForm from '../../components/EnquiryForm'

function Projects() {
  const aboutHeading = "Projects"
  const projectsecondpara = "Stopover Resorts is currently under construction at approximately 4 Km from Raipurrani towards Nariangarh, on Raipurrani - Nariangarh Road (NH-73A), Distt Panchkula. Resort buiildings including cottages, Convention Hall & a Club Houseare being constructed using SAB technology. "
  return (
    <>
      <section>
        <div className="inner-banner">
            <div className="container custom-container">
                <div className="breadcrumb-wrapper">
                    <div className="about-heading text-center">
                        <h2>{aboutHeading}</h2>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Projects</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
      </section>


      <section>
        <div className='inner-page-wrapper'>
          <div className='container custom-container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='projects-wrapper pe-3'>
                    <h3>Our Projects</h3>
                    <div className='section-para d-none'>
                        {parse(projectsecondpara)}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mt-5'>
                <div className='col-lg-4 col-md-6 col-sm-6 mb-4'>
                  <div className='project-img'>
                    <img className='w-100' src={projectImage1} alt='' title='' />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 mb-4'>
                  <div className='project-img'>
                    <img className='w-100' src={projectImage2} alt='' title='' />
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 mb-4'>
                  <div className='project-img'>
                    <img className='w-100' src={projectImage3} alt='' title='' />
                  </div>
                </div>
                
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='project-img'>
                    <img className='w-100' src={projectImage4} alt='' title='' />
                  </div>
                </div>
                
                <div className='col-lg-4 col-md-6 col-sm-6'>
                  <div className='project-img'>
                    <img className='w-100' src={projectImage5} alt='' title='' />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>

      <EnquiryForm/>
    </>
  )
}

export default Projects