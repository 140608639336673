import React from 'react'
import parse from 'html-react-parser'
import headingLeaft from '../../assets/images/leaft.png'
import iconOne from '../../assets/images/energy.svg'

function KnowEnfocus() {
    
    const sectionHeading= 'Know Enfocus'
  return (
    <>
        <section>
            <div className='know-enfocus section-padding'>
                <div className='container custom-container'>
                    <div className='row align-items-center'>
                        <div className='enfocus-heading'>
                            <span className='text-uppercase fw-bold mb-xl-3 mb-2 d-flex align-items-center'><img src={headingLeaft} alt='' title='' /> Our Features</span>
                            <h2 className='fw-bold mb-0'>{parse(sectionHeading)}</h2>
                        </div>
                    </div>
                    <div className='row mt-xl-5 mt-4 gx-5 gy-xl-0 gy-md-4'>
                        <div className='col-xl-4 col-md-6 col-sm-6'>
                            <div className='feature-card text-center smooth position-relative overflow-hidden'>
                                <div className='feature-header position-relative z-1'>
                                    <div className='feature-icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                                        <img src={iconOne} alt='' title='' />
                                    </div>
                                </div>                        
                                <div className='feature-body mt-xl-4 mt-3 position-relative z-1'>
                                    <div className='feature-title'>
                                        <h3 className='fw-bold smooth'>Latest and State -of-the art Technology for </h3>
                                    </div>
                                    <div className='feature-points text-left'>
                                        <ul className='list-unstyled m-0 p-0'>
                                            <li><span>Photo-voltaic panels  </span></li>
                                            <li><span>Inverters </span></li>
                                            <li><span>Wind Turbines</span></li>
                                            <li><span>Mounting and racking systems </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6 col-sm-6'>
                            <div className='feature-card text-center smooth position-relative overflow-hidden'>
                                <div className='feature-header position-relative z-1'>
                                    <div className='feature-icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                                        <img src={iconOne} alt='' title='' />
                                    </div>
                                </div>                        
                                <div className='feature-body mt-xl-4 mt-3 position-relative z-1'>
                                    <div className='feature-title'>
                                        <h3 className='fw-bold smooth'>Durability & Strength  </h3>
                                    </div>
                                    <div className='feature-points text-left'>
                                        <ul className='list-unstyled m-0 p-0'>
                                            <li><span>PV Panels should retain higher efficiency over their life-time  </span></li>
                                            <li><span>Inverters are provided with longest guarantee time in the industry </span></li>
                                            <li><span>Mounting structures and racking are light, yet strong and durable</span></li>
                                            <li><span>Installed energy Systems embrace up to 150 KMPH winds </span></li>
                                            <li><span>Entire energy generating systems maintain structural integrity of the buildings with minimal roof penetrations.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4 col-md-6 col-sm-6'>
                            <div className='feature-card text-center smooth position-relative overflow-hidden'>
                                <div className='feature-header position-relative z-1'>
                                    <div className='feature-icon rounded-circle d-flex align-items-center justify-content-center m-auto'>
                                        <img src={iconOne} alt='' title='' />
                                    </div>
                                </div>                        
                                <div className='feature-body mt-xl-4 mt-3 position-relative z-1'>
                                    <div className='feature-title'>
                                        <h3 className='fw-bold smooth'>Construction  </h3>
                                    </div>
                                    <div className='feature-points text-left'>
                                        <ul className='list-unstyled m-0 p-0'>
                                            <li><span>Undertaken by trained crews keeping in view all safety aspects </span></li>
                                            <li><span>Least construction time using improved hardware and innovative procedures </span></li>
                                            <li><span>Ensuring laid down electrical safety standards including system-wide proper grounding </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default KnowEnfocus