import {React, useState} from 'react'
import whiteLogo from '../assets/images/enfocus-white.png'
import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Nav, Navbar } from 'react-bootstrap-v5'

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <header>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col enfocus-logo-col text-center'>
                <Navbar.Toggle className="shadow-none navbar-toggle" aria-controls="basic-navbar-nav" onClick={()=>setExpanded(prev=>!prev)} />
                
              <Link to='/'>
                <img src={whiteLogo} alt='' title='' />
              </Link>
            </div>
            <div className='col responsive-display'>
              <div id='enfocus-nav'>
              <Navbar expanded={expanded} expand="lg" className="navbar-nav smooth p-0">
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav as='ul' className="me-auto d-flex align-items-center text-uppercase">
                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to='/'>Home</NavLink></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(!expanded)}   as='li'><NavLink className='text-white nav-link' to="/products">Solar EPC</NavLink></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/projects">Projects</NavLink></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link' to="/media">Media</NavLink></Nav.Item>
                    <Nav.Item onClick={() => setExpanded(!expanded)} as='li'><NavLink className='text-white nav-link'  to='/contact-us'>Contact Us</NavLink></Nav.Item>
                </Nav>
                </Navbar.Collapse>
              </Navbar>
              </div>
            </div>
            <div className='col enquiry-col'>
              <div className='header-right h-100 d-flex align-items-center jus'>
                <div className='quick-enquiry d-flex align-items-center justify-content-center'>
                  <div className='enquiry-icon rounded-circle d-flex align-items-center justify-content-center text-white'>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className='enquiry-text text-white ps-3 fw-fw-normal'>
                    <small className='d-block opacity-75'>Quick Enquiry</small>
                    <a className='fw-medium text-white text-decoration-none' href='mailto:info@enfocus.in'>
                      info@infocus.in
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header