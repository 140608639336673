import React from 'react'
import aboutImage from '../../assets/images/about.png'
import headingLeaft from '../../assets/images/leaft.png'
import parse from 'html-react-parser'
import EnquiryForm from '../../components/EnquiryForm'

function AboutUs() {
    const sectionHeading = 'What We Do'
    const para = '<p>We, Enfocus Energy, are proud providers of Consultation, Design, Engineering, fabrication, Integration and installation of, Commercial Carport based PV Solar systems, Commercial, Residential roof top PV Solar Systems and innovative small wind turbines for commercial and high-rise residential buildings. We undertake above services individually or as turnkey projects. Once the client places an order, provides the site and Govt permits etc., we start the projects and hand over an operational system ready to be interconnected to the grid. Should the client desire, we shall also undertake liaison with the Govt and electric Company for permission/interconnection.</p><p>Enfocus is dedicated to offer an affordable yet durable and high-quality energy resources for both, residential as well as commercial clients. We recognize that the energy generation systems are a long-term investment for our clients and must operate with highest efficiency over its life- time, therefore, have to be carefully designed, should have state of the art and efficient hardware, should have minimal effect on structural integrity of the buildings and should be made operational in as less a time as possible.</p>'
    const aboutHeading = "About Us"
  return (
    <>
        <section>
            <div className="inner-banner">
                <div className="container custom-container">
                    <div className="breadcrumb-wrapper">
                        <div className="about-heading text-center">
                            <h2>{aboutHeading}</h2>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* end banner */}

        <section>
            <div className='inner-page-wrapper'>
                <div className='container custom-container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 pe-lg-4'>
                            <div className='home-about-img'>
                                <img className='w-100' src={aboutImage} alt={sectionHeading} title={sectionHeading} />
                            </div>
                        </div>
                        <div className='col-lg-6 ps-xxl-5'>
                            <div className='enfocus-heading'>
                                <span className='text-uppercase fw-bold mb-xl-3 mb-2 d-flex align-items-center'><img src={headingLeaft} alt='' title='' /> About Us</span>
                                <h2 className='fw-bold'>{parse(sectionHeading)}</h2>
                            </div>
                            <div className='section-para'>
                                {parse(para)}
                            </div>
                            <div className='feature-points d-none'>
                                <ul className='list-unstyled m-0 p-0'>
                                    <li><span>High efficiency</span></li>
                                    <li><span>Outstanding low-light performance</span></li>
                                    <li><span>High transparent low-iron, tempered glass</span></li>
                                    <li><span>25 year transferable power output warranty</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end about section */}

        <EnquiryForm/>
    </>
  )
}

export default AboutUs
